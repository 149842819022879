import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import { Viewer } from "@toast-ui/react-editor";
import { useUser } from "../context/user-context";
import DOMPurify from "dompurify";
import HeaderComponent from "./HeaderComponent";
import PropTypes from "prop-types";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";

const customSanitizer = html => {
  return DOMPurify.sanitize(html, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["rel", "target", "hreflang", "type"],
    FORBID_TAGS: [
      "input",
      "script",
      "textarea",
      "form",
      "button",
      "select",
      "meta",
      "style",
      "link",
      "title",
      "object",
      "base"
    ]
  });
};

export const ViewerComponent = ({ data }) => {
  const { content } = data ?? {};
  // this.registerHTMLTagToWhitelist("iframe");

  return (
    <>
      <HeaderComponent data={data} />
      {data.images && data.images.imagew640 && (
        <Container maxWidth="sm" sx={{ marginTop: 3 }}>
          <img style={{ width: "100%" }} src={data["images"]["imagew640"]} />
        </Container>
      )}
      <div className="customEditor">
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        {content && (
          <Viewer
            initialValue={content}
            customHTMLSanitizer={customSanitizer}
            plugins={[tableMergedCell]}
          />
        )}
      </div>
    </>
  );
};

ViewerComponent.propTypes = {
  data: PropTypes.object
};

export const ViewerComponentSelf = ({ data }) => {
  const { content } = data ?? {};
  const {
    state: { user: owner }
  } = useUser();

  return (
    <>
      <HeaderComponent data={{ ...data, owner: owner }} />
      <div className="customEditor">
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        {content && (
          <Viewer
            initialValue={content}
            customHTMLSanitizer={customSanitizer}
            plugins={[tableMergedCell]}
          />
        )}
      </div>
    </>
  );
};

ViewerComponentSelf.propTypes = {
  data: PropTypes.object
};
