import { orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  status: {
    danger: orange[500]
  },
  typography: {
    allVariants: {
      fontFamily: "Nunito"
    }
  }
});

export default theme;
