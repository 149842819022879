import axios from "axios";
import configs from "./../utils/configs";
import { getCookie } from "../utils/cookie";
const { baseUrl, baseAdditionalFeatureUrl } = configs;

const getApi = token =>
  axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    withCredentials: true,
    transformRequest: [data => JSON.stringify(data)],
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token || getCookie("token")}`
    }
  });

const getApiUpload = (
  token,
  { isBinary = false, withCredentials = true } = {}
) =>
  axios.create({
    baseURL: baseUrl,
    withCredentials: withCredentials,
    headers: {
      "Content-Type": isBinary ? "application/xml" : "multipart/form-data",
      Authorization: `Bearer ${token || getCookie("token")}`
    }
  });

const getApiAdditionalFeature = token => {
  try {
    return axios.create({
      baseURL: baseAdditionalFeatureUrl,
      timeout: 30000,
      withCredentials: false,
      transformRequest: [data => JSON.stringify(data)],
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        // Cookie can't  be read in serverside, so if you calling this function from serverside
        // please pass token as parameter
        Authorization: `Bearer ${token || getCookie("token")}`
      }
    });
  } catch (e) {
    console.error(
      "error in getting token, if you calling function in server-side please pass tokean via parameter"
    );
  }
};


export { getApi, getApiUpload, getApiAdditionalFeature };
