import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useUser } from "./context/user-context";
import theme from "./theme";
import "@toast-ui/editor/dist/toastui-editor.css";
import "./styles/toast-ui-overide.css";
import getSelf from "./api/accountApi";
import "./styles/typeface.css";
import Editor from "./pages/Editor";
import Edit from "./pages/[id]/Edit";
import Viewer from "./pages/Viewer";
import { decrypt } from "./utils/hash";
import { getCookie } from "./utils/cookie";
import { useSearchParams } from "react-router-dom";

const App = () => {
  const { dispatch } = useUser();

  useEffect(() => {
    fetchInitialValue();
  }, []);

  let [searchParams] = useSearchParams();

  const key = searchParams.get("key");

  const token = key ? decrypt(key) : getCookie("token");

  const fetchInitialValue = async () => {
    const { data } = await getSelf(token);
    dispatch({ type: "setUser", payload: data });
  };

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            hideIconVariant
          >
            <CssBaseline />
            <Container maxWidth="md" sx={{ py: 2 }}>
              <Routes>
                <Route path="/editor" element={<Editor />} />
                <Route path="/:id" element={<Viewer />} />
                <Route path="/:id/edit" element={<Edit />} />
              </Routes>
            </Container>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
