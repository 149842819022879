import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { getCookie } from "../utils/cookie";
import { decrypt } from "../utils/hash";
import { getApiUpload } from "./../api/Api";
import { getArticleById } from "./../api/articleApi";
import { getViewableImage } from "./../utils/getViewableImage";

const useFormArticle = ({ articleId, setEditorValue } = {}) => {
  let [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.toString();
  const token = key ? decrypt(key) : getCookie("token");

  useEffect(() => {
    if (articleId) {
      fetchData();
    }
  }, [articleId]);

  const { setValue, control, ...useFormArticle } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      description: "",
      content: "",
      title: "",
      isPrivate: true,
      postToTimeline: false,
      image: "",
      image300: "",
      location: {
        lat: "",
        lng: ""
      }
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true
  });

  const fetchData = async () => {
    const { data = {} } = await getArticleById(articleId, token);
    console.log(data, "DATA");
    setValue("description", data.description);
    setValue("content", data.content);
    setValue("title", data.title);
    setValue("isPrivate", data.isPrivate);
    setValue("postToTimeline", data.postToTimeline);
    setValue("image", data.images?.imageOri);
    setValue("image300", data.images?.image300); //for preview image on dropzone
    setValue("location.lat", data.location?.lat);
    setValue("location.lng", data.location?.long);
    if (setEditorValue) {
      setEditorValue(data.content);
    }
  };

  const uploadImage = async blob => {
    let formData = new FormData();
    formData.append("file", blob);
    const { data } = await getApiUpload(token).post(
      "/storages/svara-article-content-image/upload-file",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return getViewableImage(data.key);
  };

  return { setValue, control, ...useFormArticle, uploadImage };
};

export default useFormArticle;
