import React, { useEffect, useState } from "react";
import { ViewerComponent } from "./../components/ViewerArticle";
import { getArticleById } from "./../api/articleApi";
import { useParams, useSearchParams } from "react-router-dom";
import { getCookie } from "../utils/cookie";
import { decrypt } from "../utils/hash";

const ArticleById = () => {
  const [data, setData] = useState({});
  const search = useParams("id");
  let [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const token = key ? decrypt(key) : getCookie("token");

  useEffect(() => {
    fetchInitial();
  }, []);

  const fetchInitial = async () => {
    const { data } = await getArticleById(search.id, token);
    setData(data);
  };

  return <ViewerComponent data={data} />;
};

export default ArticleById;
