import { IconButton, Box, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar as useNotistackSnakBar } from "notistack";
const useSvaraSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnakBar();

  const fireSnackbar = (message, variant) =>
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: variant || "info",
      key: Math.random(10),
      action: (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={props => closeSnackbar(props.id)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )
    });

  const fireSnackbarWithLink = (message, variant, onClickLink) =>
    enqueueSnackbar(
      <Box>
        {message}{" "}
        <Link
          onClick={onClickLink}
          style={{
            color: "white",
            cursor: "pointer",
            textDecoration: "underline"
          }}
        >
          Learn More
        </Link>
      </Box>,
      {
        autoHideDuration: 3000,
        variant: variant || "info",
        key: Math.random(10),
        action: (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={props => closeSnackbar(props.id)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )
      }
    );
  return { fireSnackbar, fireSnackbarWithLink };
};

export { useSvaraSnackbar };
