import { getApiAdditionalFeature } from "./Api";
// import configs from "./../utils/configs";


export const getArticleById = async (id, token) => {
  try {
    // check id rri.ne:1594649
    let splitId = id.split(":");
    let newid = id;
    if (splitId.length>1) {
      newid = splitId[0] + ":" + encodeURIComponent(splitId[1]);
    }
    return getApiAdditionalFeature(token).get("/articles/" + newid);
    
    // if (splitId.length == 1) {
    //   return getApiAdditionalFeature(token).get("/articles/" + id);
    // } else {
    //   let { mapServicesUrl } = configs; 
    //   let mapUrl = JSON.parse(mapServicesUrl);
    //   let urlPath = mapUrl[splitId[0]];
    //   // console.log(urlPath);
    //   let result = await getApi(token).get(urlPath+"?"+decodeURIComponent(splitId[1]));
    //   if (result.status == 200) {
    //     result.data = result.data.dataList[0];
    //     return result;
    //   }
    //   return {};
    // }
  } catch (e) {
    console.error(e);
  }
};

export const editArticleById = (id, data, token) => {
  try {
    let splitId = id.split(":");
    if (splitId.length > 1) throw new Error(`Unsuperted external edit: ${id}`);
    return getApiAdditionalFeature(token).put("/articles/" + id, data);
  } catch (e) {
    console.error(e);
  }
};

export const createArticle = (data, token) => {
  try {
    return getApiAdditionalFeature(token).post("/articles", data);
  } catch (e) {
    console.error(e);
  }
};
