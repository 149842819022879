import { Box, Button, ButtonBase } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { any, bool, func, object, string } from "prop-types";
import { useDropzone } from "react-dropzone";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
const useStyles = makeStyles(theme => ({
  imageDropzone: {
    width: "100%",
    position: "relative",
    paddingTop: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 5
  },
  textContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    borderRadius: 5
  },
  noImage: {
    border: `1px solid ${theme.palette.text.primary}`
  },
  icon: {
    fontSize: 42
  },
  chooseVideo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    display: "flex"
  }
}));

const Dropzone = ({
  previewImage,
  onDrop,
  configs,
  style,
  className,
  icon: Icon,
  withoutIcon,
  text
}) => {
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...configs
  });

  return (
    <Box
      marginBottom={2}
      style={{
        backgroundImage: `url(${previewImage})`,
        ...style
      }}
      className={clsx(
        className,
        classes.imageDropzone,
        !previewImage ? classes.noImage : null
      )}
    >
      <Box
        id="inputBox"
        component={ButtonBase}
        className={classes.textContainer}
        {...getRootProps()}
      >
        <input {...getInputProps()}></input>

        {!withoutIcon && <DropzoneIcon Icon={Icon} />}
        <Box textAlign="center">{text}</Box>
      </Box>
    </Box>
  );
};

const SmallDropzone = ({ onDrop, configs, placeholder }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...configs
  });
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.imageDropzone, classes.noImage)}
      style={{ paddingTop: "30%" }}
    >
      <Button
        {...getRootProps()}
        className={classes.chooseVideo}
        style={{ maxWidth: "80%" }}
      >
        <input {...getInputProps()}></input>

        <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {placeholder}
        </Box>
      </Button>
    </Box>
  );
};

Dropzone.propTypes = {
  previewImage: string,
  configs: object,
  onDrop: func.isRequired,
  text: string,
  icon: any,
  withoutIcon: bool
};

const DropzoneIcon = ({ Icon }) => {
  const classes = useStyles();
  return Icon ? (
    <Icon className={classes.icon} />
  ) : (
    <CameraAltOutlinedIcon className={classes.icon} />
  );
};

DropzoneIcon.propTypes = {
  Icon: any
};

export { SmallDropzone };
export default Dropzone;
