import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import useTranslation from "../hooks/useTranslation";
import { getApiUpload } from "../api/Api";
import { createArticle, editArticleById } from "../api/articleApi";
import { ViewerComponentSelf } from "./ViewerArticle";
import { useSvaraSnackbar } from "./../hooks/useSvaraSnackbar";
import Dropzone from "./Dropzone";
import { getCookie } from "./../utils/cookie";
import { LeftRightFlex, TabPanel } from "./TabPanel";
import useFormArticle from "./../hooks/useFormArticle";
import { Editor } from "@toast-ui/react-editor";
import { useSearchParams } from "react-router-dom";
import { decrypt } from "../utils/hash";
import PropTypes from "prop-types";
import { useUser } from "../context/user-context";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";
/* Prefent self is not defined error */

const CreateArticleForm = ({ articleId, disableSetMark }) => {
  const editorRef = useRef(null);
  const language = "en";
  const { t } = useTranslation(language);
  const [imagePlaceholder, setImagePlaceholder] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  let [searchParams] = useSearchParams();
  const {
    state: { user: owner }
  } = useUser();

  const APP_ID = searchParams.get("appId");
  const key = searchParams.get("key");
  const radioId = searchParams.get("radioId");

  const appId = APP_ID || getCookie("appId");

  const token = key ? decrypt(key) : getCookie("token");
  // const appId = APP_ID || getCookie("appId");

  // tempVariable when switch tab
  const [content, setContent] = useState();
  const [title, setTitle] = useState();

  const setEditorValue = value => {
    if (value && !disableSetMark)
      editorRef?.current?.getInstance?.().setMarkdown(value);
  };

  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    uploadImage,
    reset
  } = useFormArticle({
    articleId,
    setEditorValue
  });

  useEffect(() => {
    setTimeout(() => {
      setImagePlaceholder(getValues("image300"));
    }, 1000);
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const { fireSnackbar } = useSvaraSnackbar();

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setContent(editorRef?.current?.getInstance?.().getMarkdown());
      setTitle(getValues("title"));
    }
    setActiveTab(newValue);
  };

  return (
    <form
      onSubmit={handleSubmit(async data => {
        let image = data.image;

        if (image?.path) {
          let formData = new FormData();
          formData.append("file", image);
          const { data } = await getApiUpload(token).post(
            "/storages/svara-upload-image/upload-file",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          );

          image = data.key;
        }

        const newData = {
          ...data,
          appId,
          image: image
        };
        delete newData.image300; //delete image300 from body

        newData.location.lat = parseFloat(data.location.lat) || 0;
        newData.location.lng = parseFloat(data.location.lng) || 0;

        if (!articleId) {
          if (radioId) {
            newData.ownerType = "Radio";
            newData.ownerId = radioId;
          } else {
            newData.ownerType = "Account";
            newData.ownerId = owner.id;
          }
        }

        try {
          const { data } = articleId
            ? await editArticleById(articleId, newData, token)
            : await createArticle(newData, token);

          fireSnackbar(
            `Article has been successfully ${
              !articleId ? "published" : "edited"
            }`
          );

          try {
            // Keperluan android webview
            // eslint-disable-next-line no-undef
            SvaraApp.closeWebView();
          } catch (e) {
            // apapun kalo si web tidak di buka di android
            console.log(e);
          }

          // Keperluan untuk komunikasi ke dashboard web
          window?.parent?.postMessage(
            {
              func: "onArticleSend",
              message: "Article has been successfully created",
              data: data
            },
            "*"
          );

          reset();
        } catch (e) {
          console.log(e);
          fireSnackbar("Failed to publish article", "error");
        }
      })}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Editor" {...a11yProps(0)} />
        <Tab label="Preview" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <Box marginBottom={3}>
          <LeftRightFlex>
            <div className="left">
              <Controller
                control={control}
                name="image"
                render={({ onChange }) => {
                  return (
                    <Dropzone
                      onDrop={acceptedFiles => {
                        onChange(acceptedFiles[0]);
                        setImagePlaceholder(
                          URL.createObjectURL(acceptedFiles[0])
                        );
                      }}
                      text="Cover Image"
                      previewImage={imagePlaceholder}
                      configs={{
                        accept: "image/jpeg, image/png, image/jpg"
                      }}
                    />
                  );
                }}
              />
            </div>

            <div className="right">
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          placeholder={t("article_form_title")}
                          fullWidth
                          variant="standard"
                          value={value}
                          data-testid="title"
                          onChange={onChange}
                          name="title"
                          sx={{
                            marginBottom: 2,
                            "& .MuiInput-input": {
                              fontSize: "3rem",
                              fontWeight: 700,
                              lineHeight: 1
                            }
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    data-testid="description"
                    control={control}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          rows={3}
                          multiline
                          placeholder={t("description") + "..."}
                          variant="standard"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          name="description"
                          sx={{
                            marginBottom: 2
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: "flex", gap: "24px" }}>
                  <Controller
                    name="location.lat"
                    data-testid="location.lat"
                    control={control}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          placeholder="Latitude"
                          variant="standard"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          name="location.lat"
                          sx={{
                            marginBottom: 2
                          }}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="location.lng"
                    data-testid="location.lng"
                    control={control}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          placeholder="Longitude"
                          variant="standard"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          name="location.lng"
                          sx={{
                            marginBottom: 2
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item container justifyContent="space-between" xs={12}>
                  <LeftRightFlex>
                    <Controller
                      name="isPrivate"
                      control={control}
                      render={({ onChange, value }) => {
                        return (
                          <RadioGroup
                            name={"isPrivate"}
                            row
                            value={value ? "private" : "public"}
                            onChange={e =>
                              onChange(e.target.value === "private")
                            }
                          >
                            <FormControlLabel
                              value="public"
                              control={<Radio color="primary" />}
                              label="Public"
                            />
                            <FormControlLabel
                              value="private"
                              control={<Radio color="primary" />}
                              label="Private"
                            />
                          </RadioGroup>
                        );
                      }}
                    />

                    <Controller
                      name="postToTimeline"
                      label={t("postToTimeline")}
                      control={control}
                      render={({ onChange, value }) => {
                        return (
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            label="Post to timeline"
                            control={
                              <Checkbox
                                name="postToTimeline"
                                checked={value}
                                onChange={e => {
                                  onChange(e.target.checked);
                                }}
                              />
                            }
                          />
                        );
                      }}
                    />
                  </LeftRightFlex>
                </Grid>
              </Grid>
            </div>
          </LeftRightFlex>
        </Box>

        <Controller
          name="content"
          control={control}
          render={({ value }) => {
            return (
              <div className="customEditor">
                <Editor
                  previewStyle="vertical"
                  initialValue={value}
                  test
                  height="auto"
                  initialEditType="wysiwyg"
                  placeholder={t("article_form_placeholder")}
                  useCommandShortcut={true}
                  hooks={{
                    addImageBlobHook: async (blob, callback) => {
                      const uploadedImageURL = await uploadImage(blob);
                      callback(uploadedImageURL, "alt text");
                      return false;
                    }
                  }}
                  events={{
                    change: () => {
                      if (!editorRef.current) {
                        return;
                      }
                      const instance = editorRef.current.getInstance();
                      setValue("content", instance.getMarkdown());
                    }
                  }}
                  ref={editorRef}
                  plugins={[tableMergedCell]}
                />
              </div>
            );
          }}
        />
        <div
          style={{ display: "flex", justifyContent: "right", marginTop: 16 }}
        >
          <Button variant="contained" disableElevation type="submit">
            {articleId ? t("article_edit") : t("article_publish")}
          </Button>
        </div>
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <ViewerComponentSelf data={{ content, title }} />
      </TabPanel>
    </form>
  );
};

CreateArticleForm.propTypes = {
  articleId: PropTypes.string,
  disableSetMark: PropTypes.bool
};

export default CreateArticleForm;
